<template>
  <a-button style="margin-bottom: 10px" type="primary" @click="addActive">新建活动</a-button>
  <a-table
    bordered
    rowKey="logicId"
    size="middle"
    :columns="columns"
    :data-source="source"
    :pagination="pagination"
    :loading="loading"
  >
    <template #available="{ record }">{{ btnLabel(record.available) }}</template>
    <template #operation="{ record }">
      <a-button
        type="primary"
        size="small"
        style="margin-right: 10px"
        @click="editActive(record)"
      >修改</a-button>
      <a-button type="primary" size="small" @click="changeStatus(record)">
        {{
          btnLabel(!record.available)
        }}
      </a-button>
    </template>
  </a-table>
  <a-modal
    :title="modalTitle"
    v-model:visible="modalVisible"
    :confirm-loading="modalLoading"
    @ok="handleOk"
    @cancel="handlerCancel"
    width="500px"
  >
    <a-form
      ref="formRef"
      :model="formModal.data"
      :rules="formModal.rules"
      :label-col="{ span: 0 }"
      :wrapper-col="{ span: 19 }"
    >
      <a-form-item label="活动名称" name="activityName">
        <a-input v-model:value="formModal.data.activityName" />
      </a-form-item>
      <!-- <a-form-item label="助力上限" name="missionTopLimit">
        <a-input-number
          v-model:value="formModal.data.missionTopLimit"
          :min="0"
          :step="1"
          :precision="0"
          style="width: 100%"
        />
      </a-form-item>-->
      <a-form-item label="领取上限" name="receiveTopLimit">
        <a-input-number
          v-model:value="formModal.data.receiveTopLimit"
          :min="0"
          :step="1"
          :precision="0"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item label="示例凭证" name="picture">
        <a-upload
          v-model:fileList="fileList"
          name="file"
          accept="image/*"
          list-type="picture"
          :action="qiniuUrl"
          :data="fileData"
          :beforeUpload="handlerBeforeUpload"
          @change="handlerFileChange"
        >
          <a-button>上传</a-button>
        </a-upload>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { onMounted, ref } from "vue";
import urls from "@/common/urls.js";
import {
  getActiveList,
  addAndUpdateActive,
  toggleActive,
  getDetail
} from "@/api/active.js";
import { uploadFile } from "@/api/planet.js";
import { message } from "ant-design-vue";

export default {
  setup() {
    const columns = ref([
      { title: "ID", dataIndex: "logicId", width: "15%" },
      { title: "标题", dataIndex: "activityName", width: "50%" },
      {
        title: "状态",
        dataIndex: "available",
        width: "15%",
        slots: { customRender: "available" }
      },
      {
        title: "操作",
        dataIndex: "operation",
        width: "20%",
        slots: { customRender: "operation" }
      }
    ]);
    const source = ref([]);
    const loading = ref(false);
    const pagination = ref({
      position: "bottom",
      total: 0, // 条数
      defaultPageSize: 20, // 默认分页条数
      pageSizeOptions: ["5", "10", "15", "20", "30"],
      showSizeChanger: true, // 是否可以改变 pageSize
      showQuickJumper: true, // 是否可以快速跳转至某页
      showTotal: total => `共 ${total} 条`,
      hideOnSinglePage: false, // 只有一页时是否隐藏分页器
      onChange: (selectedRowKeys, selectedRows) => {
        loading.value = true;
        getList(selectedRowKeys, selectedRows);
      }
    });

    const activeRowId = ref("");
    const formRef = ref();
    const modalTitle = ref("新建互助活动");
    const modalVisible = ref(false);
    const modalLoading = ref(false);
    const formModal = ref({
      data: {
        activityName: "",
        missionTopLimit: 0,
        receiveTopLimit: 0,
        picture: ""
      },
      rules: {}
    });
    const handlerCancel = async () => {
      modalVisible.value = false;
      modalLoading.value = false;
      resetFormModal();
    };
    const handleOk = () => {
      formRef.value
        .validate()
        .then(() => {
          modalLoading.value = true;
          const params = { ...formModal.value.data };
          if (activeRowId.value) {
            params.logicId = activeRowId.value;
          }
          addAndUpdateActive(params).then(({ status, msg }) => {
            if (status === "200") {
              message.success(msg);
              getList();
              handlerCancel();
            } else {
              message.error(msg);
            }
          });
        })
        .catch(error => {
          console.log("error", error);
        })
        .finally(() => {
          modalLoading.value = false;
        });
    };

    const fileList = ref([]);
    const qiniuUrl = ref(urls.qiniuBase);
    const fileData = ref({});
    const handlerBeforeUpload = async file => {
      const { data } = await uploadFile(file.name);
      fileData.value = data;
    };
    const handlerFileChange = ({ file }) => {
      const { status, response } = file;
      if (status === "done") {
        formModal.value.data.picture = `${urls.qiniu}${response.key}`;
        if (response.key) {
          fileList.value = [
            {
              name: response.key,
              response: { key: response.key },
              status: "done",
              url: `${urls.qiniu}${response.key}`,
              uid: "-1"
            }
          ];
        }
      }
    };

    const btnLabel = available => {
      return available ? "上架" : "下架";
    };
    const resetFormModal = () => {
      formRef.value.resetFields();
      formModal.value.data = {
        activityName: "",
        missionTopLimit: 0,
        receiveTopLimit: 0,
        picture: ""
      };
      fileList.value = [];
      fileData.value = {};
      activeRowId.value = "";
    };

    const addActive = () => {
      modalTitle.value = "新建互助活动";
      modalVisible.value = true;
    };
    const editActive = async row => {
      activeRowId.value = row.logicId;
      const { data } = await getDetail(activeRowId.value);
      if (!data) return;
      const { activityName, receiveTopLimit, missionTopLimit, picture } = data;
      formModal.value.data = {
        activityName,
        receiveTopLimit: receiveTopLimit || 0,
        missionTopLimit: missionTopLimit || 0,
        picture
      };
      if (picture) {
        const key = picture.split("/").pop();
        fileList.value = [
          {
            name: key || "",
            response: { key: key || "" },
            status: "done",
            url: picture,
            uid: "-1"
          }
        ];
      }
      modalTitle.value = "修改互助活动";
      modalVisible.value = true;
    };
    const getList = async (pageNo = "1", pageSize = "20") => {
      const { data, status } = await getActiveList({ pageNo, pageSize });
      if (status === "200") {
        const { total, dataOnThisPage } = data;
        pagination.value.total = total;
        source.value = dataOnThisPage;
      }
      loading.value = false;
    };
    const changeStatus = async row => {
      const { logicId, available } = row;
      const { status, msg } = await toggleActive({
        logicId,
        available: !available
      });
      if (status === "200") {
        getList();
        message.success(msg);
      } else {
        message.error(msg);
      }
    };

    onMounted(getList);

    return {
      columns,
      source,
      loading,
      pagination,
      btnLabel,

      formRef,
      modalTitle,
      modalVisible,
      modalLoading,
      handlerCancel,
      handleOk,
      formModal,

      fileList,
      qiniuUrl,
      fileData,
      handlerBeforeUpload,
      handlerFileChange,

      addActive,
      editActive,
      getList,
      changeStatus
    };
  }
};
</script>
