import { request, requestJSON } from "@/utils/axios";
import { stringify } from "qs";

// 新增更新
export function addAndUpdateActive(params) {
  return request({
    url: `/wuan/admin/helpEach/config/saveOrUpdate?${stringify(params)}`,
    method: "post"
  });
}

// 分页查询
export function getActiveList(params) {
  return request({
    url: `/wuan/admin/helpEach/config/page?${stringify(params)}`,
    method: "get"
  });
}

// 上下线
export function toggleActive(params) {
  return request({
    url: `/wuan/admin/helpEach/config/setAvailable?${stringify(params)}`,
    method: "post"
  });
}

// 任务详情
export function getDetail(id) {
  return request({
    url: `/wuan/admin/helpEach/config/detail?logicId=${id}`,
    method: "get"
  });
}

// 更新全部首页广告入口
export function updateAd(params) {
  return requestJSON({
    url: `/wuan/admin/index/ad/update`,
    method: "post",
    data: params
  });
}

// 查询全部首页广告
export function getAllAds() {
  return request({
    url: `/wuan/admin/index/ad/all`,
    method: "get"
  });
}
